import React from "react";
import styled from "styled-components";

//images
import image1 from "../images/Competitions/1.png";
import image2 from "../images/Competitions/2.png";
import image3 from "../images/Competitions/3.png";
import image4 from "../images/Competitions/4.png";

const Container = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0px 15px;
  box-sizing: border-box;
  margin-bottom: 50px;
`;

const Title = styled.h1`
  font-family: "Schoolbell", cursive;
  color: #fe4880;
  text-align: center;
  margin-bottom: 30px;
  font-size: 32px;
  
  @media (max-width: 768px) {
    font-size: 28px;
    margin-bottom: 20px;
  }
`;

const CompetitionsLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  
  @media (max-width: 768px) {
    gap: 25px;
  }
`;

const CompetitionSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  
  &:nth-child(even) {
    flex-direction: row-reverse;
  }
  
  @media (max-width: 768px) {
    flex-direction: column !important;
    align-items: center;
    gap: 15px;
    background-color: rgba(255, 215, 0, 0.1);
    padding: 15px 10px;
    border-radius: 12px;
    max-width: 100%;
    box-sizing: border-box;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  border-radius: 10px;
  overflow: hidden;
  max-width: 350px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 768px) {
    max-width: 100%;
    width: 100%;
  }
`;

const CompetitionImage = styled.img`
  width: 100%;
  height: auto;
  display: block;
`;

const InfoBox = styled.div`
  flex: 1;
  background-color: #ffd700;
  border-radius: 10px;
  padding: 20px;
  max-width: 350px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 768px) {
    max-width: 100%;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
  }
`;

const CompetitionTitle = styled.h3`
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: bold;
  
  @media (max-width: 768px) {
    font-size: 16px;
    text-align: center;
  }
`;

const WinnersList = styled.ol`
  margin: 0;
  padding-left: 25px;
  
  @media (max-width: 768px) {
    padding-left: 25px;
  }
`;

const WinnerItem = styled.li`
  margin-bottom: 5px;
  font-size: 14px;
  
  @media (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 8px;
  }
`;

const Connector = styled.div`
  height: 2px;
  background-color: #ffd700;
  flex: 0.5;
  
  @media (max-width: 768px) {
    display: none;
  }
`;

const competitions = [
  {
    title: "Competition - Mr and Miss New Blooming Buds Class- Play Group Students",
    winners: [
      "Gavya Bansal (Miss New Blooming Buds)",
      "Akansh Singh (Mr. New Blooming Buds)",
      "Tanishq (Runner up)"
    ],
    image: image1
  },
  {
    title: "Competition - Mr and Miss New Blooming Buds Class-Nursery Students",
    winners: [
      "Shwetansh (Mr New Blooming Buds)",
      "Geetanjali (Miss New Blooming Buds)",
      "Rudransh (Runner up)"
    ],
    image: image2
  },
  {
    title: "Competition - Mr and Miss New Blooming Buds Class- I Students",
    winners: [
      "Samyak Deo (Mr New Blooming Buds)",
      "Siddhi (Miss New Blooming Buds)"
    ],
    image: image3
  },
  {
    title: "Competition - Mr and Miss New Blooming Buds Class- II & III Students",
    winners: [
      "Manan (Mr New Blooming Buds)",
      "Ishani Ghai (Miss New Blooming Buds)"
    ],
    image: image4
  }
];

const Competitions = () => {
  return (
    <Container>
      <Title>COMPETITIONS</Title>
      <CompetitionsLayout>
        {competitions.map((competition, index) => (
          <CompetitionSection key={index}>
            <ImageContainer>
              <CompetitionImage src={competition.image} alt={competition.title} />
            </ImageContainer>
            <Connector />
            <InfoBox>
              <CompetitionTitle>{competition.title}</CompetitionTitle>
              <WinnersList>
                {competition.winners.map((winner, winnerIndex) => (
                  <WinnerItem key={winnerIndex}>{winner}</WinnerItem>
                ))}
              </WinnersList>
            </InfoBox>
          </CompetitionSection>
        ))}
      </CompetitionsLayout>
    </Container>
  );
};

export default Competitions;